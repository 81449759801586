<template>
  <div class="create-container">
    <div class="content">
      <div class="edit-area">
        <el-form :model="form" ref="textForm">
          <el-form-item>
            <el-input
              v-model="form.title"
              maxlength="25"
              show-word-limit
              placeholder="请输入标题"
            ></el-input>
            <button
              class="save"
              @click.prevent="saveToPhone"
              :disabled="disabled"
            >
              保存到手机
            </button>
          </el-form-item>
          <el-form-item>
            <el-input
              type="textarea"
              :rows="20"
              placeholder="请输入内容"
              v-model="form.digest"
              resize="none"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="rule">
          <p style="margin-bottom: 14px">创建规则</p>
          <p>
            请输入您需要听写的内容，保留标点或者换行，我们将据此分割语音，从而您可调节听写间隔时间。
          </p>
          <p>保留标点示例（支持标点 ， 。 ： 、 ； ？ ！ ）：</p>
          <p>光明。月亮。太阳。</p>
          <p>保留换行示例：</p>
          <p>光明</p>
          <p>月亮</p>
          <p>太阳</p>
        </div>
      </div>
      <div class="text-list">
        <div class="my-text">
          我的自建文本
          <img src="../../../assets/image/newText.png" alt="" />
        </div>
        <div class="list">
          <p
            class="list-item"
            v-for="item in list"
            :key="item.course_id"
            @click="lookItem(item)"
          >
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>

    <!-- 是否保存弹框 -->
    <el-dialog
      class="is-saved"
      :visible.sync="dialogVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <span>您创建的课件暂未保存，需要保存课件吗?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="defineSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import axios from "axios";
// axios.defaults.baseURL = "http://139.224.67.67:8866/dictation";
import {
  getTextList,
  getAText,
  createAText,
  editAText,
} from "../../../utils/request";
export default {
  data() {
    return {
      form: {
        title: "",
        digest: "",
      },
      list: [],
      dialogVisible: false,
      newOrEdit: true, //新建课件为true，编辑课件为false
      currentId: null,
      controlDefineSave: false, //true:弹窗的确定用于新建课件；false:弹窗的确定用于编辑历史课件，
      newCurrentId: null,
    };
  },
  computed: {
    disabled() {
      //标题和内容都不为空时，按钮为可点击状态；否则为不可点击状态
      return this.form.title !== "" && this.form.digest !== "" ? false : true;
    },
  },
  created() {
    getTextList().then((res) => {
      this.list = res.data;
    });
  },
  methods: {
    saveToPhone() {
      if (this.newOrEdit) {
        createAText(this.form.title, this.form.digest).then((res) => {
          //新建成功后重置表单
          this.form.title = "";
          this.form.digest = "";
          this.$message({
            message: "创建成功",
            type: "success",
          });
          getTextList().then((res) => {
            this.list = res.data;
          });
        });
      } else {
        editAText(this.form.title, this.currentId, this.form.digest).then(
          (res) => {
            this.form.title = "";
            this.form.digest = "";
            this.currentId = null;
            this.newOrEdit = true;
            // this.controlDefineSave = true;
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            getTextList().then((res) => {
              this.list = res.data;
            });
          }
        );
      }
    },
    lookItem(item) {
      //如果表单标题和内容都不为空 点击右侧文本时 弹出询问
      if (this.form.title && this.form.digest) {
        if (this.currentId === null) {
          //如果currentId为空，意味着新建文本为保存的情况下就点击了右侧历史课件
          this.controlDefineSave = true;
        }

        //如果当前表单为历史课件，未保存状态下就点了右边另一个历史课件，currentId还是当前历史课件的id，点击确定保存当前课件
        if (this.newOrEdit === false && this.controlDefineSave === false) {
          // this.currentId = item.course_id
          this.newCurrentId = item.course_id;
          this.dialogVisible = true;
          return;
        }

        this.currentId = item.course_id;
        //一开始新建课件不保存，就点击右侧历史课件，要调为编辑状态
        this.newOrEdit = false;
        this.dialogVisible = true;
        return;
      }
      this.newOrEdit = false;
      this.currentId = item.course_id;
      // this.form.title = item.title;
      // this.form.digest = item.digest;
      getAText(this.currentId).then((res) => {
        let text = "";
        res.data.contents.forEach((item) => {
          text += item.word;
        });
        this.form.digest = text;
        this.form.title = res.data.title;
      });
    },
    cancel() {
      //新建课件未保存就点击历史课件，弹窗取消的情况
      if (this.controlDefineSave) {
        console.log("取消新建");
        this.controlDefineSave = false;
      }

      // 适用于当前表单位历史课件，未保存的状态下点击了另一个历史课件，弹窗取消的情况
      if (
        this.controlDefineSave === false &&
        this.newOrEdit === false &&
        this.newCurrentId === null
      ) {
        this.newCurrentId = this.currentId;
        // this.list.forEach(item => {
        //   if (this.newCurrentId === item.course_id) {
        //     this.form.title = item.title;
        //     this.form.digest = item.digest;
        //   }
        // });
        getAText(this.newCurrentId).then((res) => {
          let text = "";
          res.data.contents.forEach((item) => {
            text += item.word;
          });
          this.form.digest = text;
          this.form.title = res.data.title;
        });
        this.dialogVisible = false;
        return;
      }

      if (
        this.controlDefineSave === false &&
        this.newOrEdit === false &&
        this.newCurrentId !== null
      ) {
        // this.newCurrentId = this.currentId;
        this.currentId = this.newCurrentId;
        // this.list.forEach(item => {
        //   if (this.newCurrentId === item.course_id) {
        //     this.form.title = item.title;
        //     this.form.digest = item.digest;
        //   }
        // });
        getAText(this.newCurrentId).then((res) => {
          let text = "";
          res.data.contents.forEach((item) => {
            text += item.word;
          });
          this.form.digest = text;
          this.form.title = res.data.title;
        });
        this.dialogVisible = false;
        return;
      }

      // this.list.forEach(item => {
      //   if (this.newCurrentId === item.course_id) {
      //     this.form.title = item.title;
      //     this.form.digest = item.digest;
      //   }
      // });
      getAText(this.newCurrentId).then((res) => {
        let text = "";
        res.data.contents.forEach((item) => {
          text += item.word;
        });
        this.form.digest = text;
        this.form.title = res.data.title;
      });
      this.dialogVisible = false;
    },
    defineSave() {
      if (this.controlDefineSave) {
        createAText(this.form.title, this.form.digest).then((res) => {
          //新建成功后重置表单
          this.controlDefineSave = false;
          this.$message({
            message: "创建成功",
            type: "success",
          });
          getTextList().then((res) => {
            this.list = res.data;
            // this.list.forEach(item => {
            //   if (this.currentId === item.course_id) {
            //     this.form.title = item.title;
            //     this.form.digest = item.digest;
            //   }
            // });
            getAText(this.currentId).then((res) => {
              let text = "";
              res.data.contents.forEach((item) => {
                text += item.word;
              });
              this.form.digest = text;
              this.form.title = res.data.title;
            });
          });
        });
      } else if (this.newOrEdit === false && this.controlDefineSave === false) {
        //如果此时表单显示的是历史课件，此时再点击右侧另一个历史课件，弹框确定事件
        editAText(this.form.title, this.currentId, this.form.digest).then(
          (res) => {
            getTextList().then((res) => {
              this.list = res.data;
              // this.list.forEach(item => {
              //   if (this.newCurrentId === item.course_id) {
              //     this.form.title = item.title;
              //     this.form.digest = item.digest;
              //     this.currentId = this.newCurrentId;
              //     this.newOrEdit = false;
              //     this.controlDefineSave = false;
              //     this.$message({
              //       message: "编辑成功",
              //       type: "success"
              //     });
              //   }
              // });
              getAText(this.newCurrentId).then((res) => {
                let text = "";
                res.data.contents.forEach((item) => {
                  text += item.word;
                });
                this.form.digest = text;
                this.form.title = res.data.title;
                this.currentId = this.newCurrentId;
                this.newOrEdit = false;
                this.controlDefineSave = false;
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
              });
            });
          }
        );
      }

      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less">
.create-container {
  padding: 65px 0;
  width: 100%;
  height: 100vh;
  background-color: #c6f1e6;
  .content {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 70%;
  }
}
.edit-area {
  .el-input {
    width: 638px;
  }
  .save {
    display: inline-block;
    margin-left: 40px;
    width: 252px;
    height: 40px;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 8px;
    text-align: center;
    background-color: #2b42f8;
    &:hover {
      cursor: pointer;
    }
    &[disabled] {
      color: rgba(255, 255, 255, 0.3);
      background-color: rgba(76, 212, 176, 0.39);
    }
  }
  .el-textarea__inner {
    width: 930px;
  }
}

.text-list {
  position: relative;
  margin-left: 40px;
  padding: 0 34px;
  width: 310px;
  height: 608px;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .my-text {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 60px;
    color: #272829;
    background-color: #fff;
    img {
      margin-left: 10px;
      width: 20px;
    }
  }
  .list {
    // margin-top: 60px;
    .list-item {
      margin-top: 18px;
      padding-bottom: 16px;
      border-bottom: 1px solid #bababa;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #272829;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.rule {
  margin-top: 34px;
  p {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    // text-align: center;
  }
}

.is-saved .el-dialog__body span {
  display: block;
  padding: 35px 0;
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #272829;
}
</style>
